import actions from './actions';
import getters from './getters';
import mutations from './mutations';

/**
 * @typedef {import('~/types/messages/messages').MessageToConversation} MessageToConversation
 */

/**
 * @typedef {Object} State
 * @property {Array} messages
 * @property {Array<MessageToConversation>} conversations
 */

/**
 * @returns {State}
 */

const state = () => ({
  messages: [],
  conversations: [],
  users: [],
  onlineUsers: [],
  messageNotifications: [],
  messageNotificationCount: 0,
  messagesWithReplies: [],
  profilesMessagePrivacySettings: null,
  privacyUpdatedDateTime: null,
  blocksUpdatedDateTime: null,
  landedFromProfileId: null,
  isPreloading: false,
  initialUsers: [],
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
