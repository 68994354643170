import * as getters from './getters';

import actions from './actions';
import mutations from './mutations';

const state = () => ({
  companySymbols: { data: null, loading: false, error: null },
  headlines: { data: null, loading: false, error: null },
  tickersToCompare: { data: null, loading: false, error: null },
  nextHeadlines: { data: null, loading: false, error: null },
  primaryTickerFundamentals: {
    data: null,
    loading: false,
    error: null,
    hasPrimaryTickerFundamentals: true,
  },
  tickerFundamentals: { data: null, loading: false, error: null },
  currentlyActiveTicker: {},
  companyPortfolios: { data: null, loading: false, error: null },
  nextCompanyPortfolios: { data: null, loading: false, error: null },
  companyProfileComments: { data: null, loading: false, error: null },
  repliesLoading: false,
  nextCommentsLoading: false,
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
