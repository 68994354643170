
import debounce from 'debounce-async';
import {
  ApiApplicationErrorCodes,
  getApplicationError,
} from '@/utils/behavior/apiErrors';
import { portfolioUniqueNameError } from '~/utils/behavior/portfolio-creation';
import Modalv2 from '~/components/ui/molecule/Modalv2.vue';

import WalletIcon from '~/components/ui/icons/WalletIcon.vue';
import InfoIcon from '~/components/ui/icons/InfoIcon.vue';
import ChevronIcon from '~/components/ui/icons/ChevronIcon.vue';

import UILeagueAllowedExchangeTags from '@/components/ui/molecule/UILeagueAllowedExchangeTags';
import UILeagueRemainingTime from '@/components/ui/molecule/UILeagueRemainingTime';

export default {
  name: 'UICreateLeaguePortfolioModal',
  components: {
    Modalv2,
    WalletIcon,
    InfoIcon,
    ChevronIcon,
    UILeagueAllowedExchangeTags,
    UILeagueRemainingTime,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledReason: {
      type: String,
      default: null,
    },
    league: {
      type: Object,
      default: null,
    },
  },
  emits: ['onClose', 'onSubmit'],
  data() {
    return {
      name: '',
      error: '',
      formValues: {
        name: '',
        showMoreInfo: false,
        type: 'classic',
        date: '',
      },
      debouncedValidatePortfolioName: debounce(this.validatePortfolioName, 500),
      portfolioNameValiadtionMessages: {
        required: this.$t('name_required'),
        max: this.$t('name_max_length', { max: 16 }),
        'back-end': this.$t('error_occured_message'),
      },
      showMoreInfo: false,
    };
  },

  computed: {
    leagueRules() {
      return this.league?.data;
    },
    isLeagueFinished() {
      if (!this.leagueRules) {
        return false;
      }
      return this.$dayjs().isAfter(this.$dayjs(this.leagueRules.endDate));
    },
    initialDate() {
      const today = this.$dayjs().$d.getDay();
      // getDay() returns 0-6 (SUNDAY IS 0!!!)
      let toSubstract = 1;
      if (today === 1) {
        toSubstract = 3;
      } else if (today === 0) {
        toSubstract = 2;
      }
      const res = this.$dayjs()
        .subtract(toSubstract, 'day')
        .format('YYYY-MM-DD');
      return res;
    },
  },
  created() {
    this.formValues.date = this.initialDate;
  },
  methods: {
    submit() {
      if (this.disabled) return;
      const payload = {
        ...this.formValues,
      };
      this.$emit('onSubmit', payload);
    },
    onClose(index) {
      this.formValues.cashAmounts.splice(index, 1);
    },
    async validatePortfolioName(name) {
      try {
        await this.$api.validatePortfolioName(name);
        return true;
      } catch (e) {
        const apiError = getApplicationError(e.errorCode);

        console.error('e', e);

        // expand this switch statement as needed
        switch (apiError) {
          // if the profile has another portfolio with the same name, show the error message
          case ApiApplicationErrorCodes.BadRequest.UniquePortfolioName:
            this.portfolioNameValiadtionMessages['back-end'] = this.$t(
              portfolioUniqueNameError
            );
            return false;
          // since we have the length validation in the front-end, we don't need to handle that error type here, so return true
          case ApiApplicationErrorCodes.BadRequest
            .PortfolioNameExceedsMaximumCharacters:
            return true;
          // if there is an error that we have not handled, we can show a generic error message
          default:
            this.portfolioNameValiadtionMessages['back-end'] = this.$t(
              'error_occured_message'
            );
            return false;
        }
      }
    },
    toggleShowMoreInfo() {
      this.showMoreInfo = !this.showMoreInfo;
    },
  },
};
