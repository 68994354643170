
export default {
  name: 'UILeagueRemainingTime',
  components: {},
  props: {
    endDate: {
      type: String,
      required: true,
    },
  },

  computed: {
    remainingTimeString() {
      let res = '';
      let endDate = this.$dayjs(this.endDate);

      const remainingDays = endDate.diff(this.$dayjs(), 'days');
      if (remainingDays > 0) {
        res += remainingDays + this.$t('day_abbreviation') + ' ';
        endDate = endDate.subtract(remainingDays, 'day');
      }

      const remainingHours = endDate.diff(this.$dayjs(), 'hour');
      if (remainingHours > 0) {
        res += remainingHours + this.$t('hour_abbreviation') + ' ';
        endDate = endDate.subtract(remainingHours, 'hour');
      }

      const remainingMinutes = endDate.diff(this.$dayjs(), 'minute');
      res += remainingMinutes + this.$t('minute_abbreviation');

      return res;
    },
    isLeagueFinished() {
      return this.$dayjs().isAfter(this.$dayjs(this.endDate));
    },
  },
};
