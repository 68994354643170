
import { mapActions } from 'vuex';
import LIKE from './LIKE';
import FOLLOW from './FOLLOW';
import REPOST from './REPOST';
import REPLY from './REPLY';
import MENTION from './MENTION';
import PORTFOLIOLIKE from './PORTFOLIOLIKE';
import PORTFOLIOCOMMENT from './PORTFOLIOCOMMENT';
import PORTFOLIOFAVORITE from './PORTFOLIOFAVORITE';
import PRICECHANGE from './PRICECHANGE';
import ADDEDTOPORTFOLIO from './ADDEDTOPORTFOLIO';
import PORTFOLIOACTION from './PORTFOLIOACTION';
import LEAGUEINVITE from './LEAGUEINVITE';
import MoreIcon from '~/components/ui/icons/MoreIcon.vue';
import { inAppRedirect } from '~/utils/route-redirects';
import DeleteTrashIcon from '~/components/ui/icons/DeleteTrashIcon.vue';
import CheckIcon from '~/components/ui/icons/CheckIcon.vue';
import { isMobile } from '~/utils/screenSizes';

export default {
  name: 'NotifItem',
  components: {
    LIKE,
    FOLLOW,
    REPOST,
    REPLY,
    MENTION,
    PORTFOLIOLIKE,
    PORTFOLIOCOMMENT,
    PORTFOLIOFAVORITE,
    PRICECHANGE,
    ADDEDTOPORTFOLIO,
    PORTFOLIOACTION,
    LEAGUEINVITE,
    MoreIcon,
    DeleteTrashIcon,
    CheckIcon,
  },
  props: {
    notif: {
      required: true,
      type: Object,
    },
    last: {
      type: Boolean,
      dafault: false,
    },
    isNotificationCenter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isMobileSize() {
      return isMobile();
    },
    cardBorderClasses() {
      let res = '';

      if (this.notif.read) {
        res += 'bg-neutral-1';
      } else {
        res += 'bg-primary-2';
      }

      if (this.isNotificationCenter) {
        res += ' border border-neutral-4';
      }

      if (!this.isMobileSize && this.isNotificationCenter) {
        res += ' rounded-lg';
      }

      return res;
    },
  },
  methods: {
    ...mapActions('notifications', [
      'removeNotification',
      'toggleNotificationRead',
    ]),
    goToProfile(profileId) {
      inAppRedirect(`/profile/${profileId}`);
    },
  },
};
