import { render, staticRenderFns } from "./UICreateLeaguePortfolioModal.vue?vue&type=template&id=4eb225b4&scoped=true"
import script from "./UICreateLeaguePortfolioModal.vue?vue&type=script&lang=js"
export * from "./UICreateLeaguePortfolioModal.vue?vue&type=script&lang=js"
import style0 from "./UICreateLeaguePortfolioModal.vue?vue&type=style&index=0&id=4eb225b4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4eb225b4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InfoIcon: require('/skade-pwa/components/ui/atom/InfoIcon.vue').default})
