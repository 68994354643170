import { blob2base64, optimizeImg } from '@/utils';
import { mapActions, mapGetters } from 'vuex';

import PullToRefresh from 'pulltorefreshjs';
import { getWindowInstance } from '~/plugins/windowInstance';
import { mapMutations } from 'vuex';

export const isMobileResponsive = {
  computed: {
    isMobileResponsive() {
      return getWindowInstance(this).isMobileResponsive();
    },
  },
};

export const pullToRefresh = {
  methods: {
    initializePullToRefresh(mainElement, onRefresh) {
      this.$data.pullToRefreshInstance = PullToRefresh.init({
        mainElement: mainElement,
        onRefresh: onRefresh,
        // https://github.com/BoxFactura/pulltorefresh.js
        // TODO find a nicer way to do this
        // Keep in mind that this PullToRefresh component only accepts HTML - not vue components.
        // So you need to provide this component the equivalent HTML of your vue component, which you can do by
        // mounting the component into a separate DOM instance, getting the HTML and destroying it
        iconArrow: `<svg class="ptr-spinner inline stroke-neutral-7" style="height:20px; width:20px; margin: 0 0 0 0;" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
   <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
   <path d="m386.3 160h-50.3c-17.7 0-32 14.3-32 32s14.3 32 32 32h128c17.7 0 32-14.3 32-32v-128c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2l-17.6-17.6c-87.5-87.5-229.3-87.5-316.8 0s-87.5 229.3 0 316.8 229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3 163.8-62.5 226.3 0l17.2 17.2z" fill="#8c8c8c"/>
  </svg>`,
        distThreshold: 65,
        distMax: 240,
        distReload: 60,
        distIgnore: 10,
        iconRefreshing:
          '<svg class="inline animate-spin" style="margin: 0 0 0 0;" xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"/></svg>',
        instructionsPullToRefresh: ' ',
        instructionsReleaseToRefresh: ' ',
        instructionsRefreshing: ' ',
        shouldPullToRefresh: false,
        refreshTimeout: 375,
        // use this to change how reactive the pull is when PTR-ing
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        resistanceFunction: (t) => 0.7,
      });
    },
    destroyPullToRefresh() {
      this.$data.pullToRefreshInstance?.destroy?.();
      this.$data.pullToRefreshInstance = undefined;
    },
  },
};

export const topNavHeader = {
  methods: {
    ...mapMutations('ui-messaging', [
      'SET_PAGE_TITLE',
      'SET_PAGE_NESTED',
      'SET_PAGE_MENU_MODE',
      'SET_PAGE_ACTION',
    ]),
    /**
     * Set top nav header. If no props are passed, it will reset the header to the default state.
     * @param {object} props Object containing the following properties:
     * @param {boolean} props.pageNested Whether the page is nested within another page.
     * @param {string} props.pageTitle The title of the page.
     * @param {string} props.pageMenuMode The menu mode of the page.
     * @param {string} props.pageActionRoute The route of the action button.
     * @param {string} props.pageActionIcon The icon of the action button.
     */
    setTopNavHeader(
      props = {
        pageNested: false,
        pageTitle: '',
        pageMenuMode: 'burgerMenu',
        pageActionRoute: undefined,
        pageActionIcon: 'profile',
      }
    ) {
      const {
        pageNested,
        pageTitle,
        pageMenuMode,
        pageActionRoute,
        pageActionIcon,
      } = props;
      this.SET_PAGE_NESTED(pageNested);
      this.SET_PAGE_TITLE(pageTitle);
      this.SET_PAGE_MENU_MODE(pageMenuMode);
      this.SET_PAGE_ACTION({
        route: pageActionRoute,
        icon: pageActionIcon,
      });
    },
  },
};

// get media of post if is images or gif
export const getMedia = {
  methods: {
    getMedia(post) {
      if (post.entityType == 'NEWS') {
        return post.newsArticleDetail.thumbnailUri
          ? [{ src: post.newsArticleDetail.thumbnailUri }]
          : [];
      } else if (post.images && post.images.length) {
        return post.images.map((e) => ({ src: e.uri }));
      } else if (post.gif) {
        //TODO bad methode to get gif
        return [
          {
            // eslint-disable-next-line no-undef
            src: `${process.env.NUXT_ENV_GIPHY_URI}${post.gif}?api_key=${process.env.NUXT_ENV_GIPHY_API_KEY}`,
          },
        ];
      }
    },
  },
};

// get assets uri from api
export const buildAssetUri = {
  methods: {
    buildAssetUri(src, defaultAsset = '') {
      if (!src) {
        return defaultAsset;
      } else if (
        src.indexOf('/_nuxt/assets/') == 0 ||
        src.indexOf('http://') == 0 ||
        src.indexOf('https://') == 0 ||
        src.indexOf('data:image') == 0
      ) {
        return src;
      } else {
        // eslint-disable-next-line no-undef
        return process.env.NUXT_ENV_ASSETS_URL + src;
      }
    },
  },
};

// upload post image
export const uploadPostImage = {
  computed: {
    ...mapGetters('post', ['uploadPostImageRes']),
  },
  methods: {
    ...mapActions('post', ['uploadPostImage']),
    uploadImages(images) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        for (const image of images) {
          const optimizedImage = await optimizeImg(image);

          const formData = new FormData();
          formData.append('file', new File([optimizedImage], 'image.jpeg'));

          await this.uploadPostImage(formData);

          if (this.uploadPostImageRes.data) {
            image.preview = await blob2base64(optimizedImage);
            image.id = this.uploadPostImageRes.data.id;
          }
        }

        resolve(images);
      });
    },

    // TODO fix this
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    removeImage(_id) {
      // TODO
    },
  },
};

// search mentions
export const searchMentions = {
  computed: {
    ...mapGetters('search', ['searchRes']),
  },
  methods: {
    ...mapActions('search', ['search']),
    async searchMentions(searchMentions) {
      return new Promise((resolve) => {
        if (!searchMentions) {
          resolve([]);
        } else {
          this.search({
            query: searchMentions,
            type: '@',
            limit: 5,
            includeCompanyMention: true,
          }).finally(() => {
            if (this.searchRes.data && this.searchRes.data.mentions) {
              resolve(
                this.searchRes.data.mentions.map((e) => ({
                  id: e.profileId,
                  value: e.profileName,
                  displayName: e.displayName,
                  avatarUri: e.avatarUri,
                  char: '@',
                })) || []
              );
            } else {
              resolve([]);
            }
          });
        }
      });
    },
  },
};

// search hashtags
export const searchHashtags = {
  computed: {
    ...mapGetters('search', ['searchRes']),
  },
  methods: {
    ...mapActions('search', ['search']),
    async searchHashtags(searchMentions) {
      return new Promise((resolve) => {
        if (!searchMentions) {
          resolve([]);
        } else {
          this.search({ query: searchMentions, type: '#', limit: 5 }).finally(
            () => {
              if (this.searchRes.data && this.searchRes.data.hashtags) {
                resolve(
                  this.searchRes.data.hashtags.map((e) => ({
                    id: e.id,
                    value: e.hashtag,
                    char: '#',
                  })) || []
                );
              } else {
                resolve([]);
              }
            }
          );
        }
      });
    },
  },
};
