
export default {
  name: 'TruncateParagraph',
  props: {
    maxLines: {
      type: Number,
      default: 3,
    },
    text: {
      default: '',
      type: String,
    },
    htmlText: {
      default: '',
      type: String,
    },
    // Optional prop to use when multiple of this is shown on the same page as an id randomizer
    idSuffix: {
      default: '',
      type: String,
    },
  },
  data() {
    return {
      truncated: false,
      truncatable: false,
    };
  },
  computed: {
    clampClass() {
      return 'clamp-' + this.maxLines;
    },
    elementId() {
      return 'truncated-text-' + this.idSuffix;
    },
    buttonId() {
      return 'read-more-' + this.idSuffix;
    },
  },
  mounted() {
    this.setTruncable();
  },

  methods: {
    handleReadMore() {
      const el = document.getElementById(this.elementId);
      el.classList.remove(this.clampClass);
      this.truncated = false;
    },
    handleReadLess() {
      const el = document.getElementById(this.elementId);
      el.classList.add(this.clampClass);
      this.truncated = true;
    },
    setTruncable() {
      const el = document.getElementById(this.elementId);
      if (
        el.offsetHeight < el.scrollHeight ||
        el.offsetWidth < el.scrollWidth
      ) {
        // your element has an overflow, ie. truncated
        // show read more button
        this.truncated = true;
        this.truncatable = true;
      } else {
        // your element doesn't have overflow
        this.truncated = false;
        this.truncatable = false;
      }
    },
  },
};
