
export default {
  name: 'HelpIcon',
  props: {
    stroke: {
      type: String,
      default: null,
    },
    fill: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: '20',
    },
  },
  emits: ['back'],
  computed: {
    fillAndStrokeClass() {
      let res = '';
      // In order to trigger css animations we need to set the default stroke and fill here
      res += 'stroke-' + (this.stroke ? this.stroke : 'neutral-10');
      res += ' fill-' + (this.fill ? this.fill : 'none');

      return res;
    },
  },
};
