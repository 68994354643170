
import Modal from '../molecule/Modal.vue';
import CloseIcon from '~/components/ui/icons/CloseIcon.vue';
import AddIcon from '~/components/ui/icons/AddIcon.vue';
import GlobeIcon from '~/components/ui/icons/GlobeIcon.vue';
import LockIcon from '~/components/ui/icons/LockIcon.vue';
import Toggle from '~/components/ui/molecule/Toggle.vue';
import UIProfileSearchWithCardsV2 from '~/components/ui/organism/UIProfileSearchWithCardsV2.vue';

export default {
  name: 'UICreateLeagueModal',
  components: {
    CloseIcon,
    AddIcon,
    Modal,
    GlobeIcon,
    LockIcon,
    Toggle,
    UIProfileSearchWithCardsV2,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onClose', 'onSubmit'],
  data() {
    return {
      name: '',
      error: '',
      currencies: [],
      hasMaxPlayerCount: false,
      formValues: {
        name: '',
        startDate: '',
        endDate: '',
        privacy: 'public',
        currency: '',
        type: 'classic',
        cashAmounts: [
          {
            id: 1,
            currencyCode: '',
            amount: '',
          },
        ],
        hasMaxPlayerCount: false,
        maxPlayerCount: 0,
        allowedExchanges: '',
        isRanked: false,
      },
      profilesToInvite: [],
    };
  },
  computed: {
    getInvitationText() {
      return (
        this.$t('league_table_player_count') +
        ' (' +
        this.profilesToInvite.length +
        '/' +
        (this.formValues.hasMaxPlayerCount
          ? this.formValues.maxPlayerCount
          : '∞') +
        ')'
      );
    },
  },
  beforeMount() {
    this.currencies = this.$display.money.getAvailableCurrencyNames();
    this.formValues.cashAmounts[0].currencyCode = this.currencies[0];
  },
  methods: {
    submit() {
      this.formValues.isPublic = this.formValues.privacy == 'public';
      const payload = {
        ...this.formValues,
        invitedProfiles: this.profilesToInvite.map(
          (profile) => profile.profileId
        ),
      };
      this.$emit('onSubmit', payload);
    },
    addMoreCash() {
      this.formValues.cashAmounts.push({
        id: this.formValues.cashAmounts.length + 1,
        currencyCode: '',
        amount: '',
      });
    },
    getNonSelectedCurrencies(clickedValue = null) {
      const nonSelected = [...this.currencies];
      this.formValues.cashAmounts.forEach((co) => {
        const index = nonSelected.indexOf(co.currencyCode);
        if (index >= 0 && clickedValue !== co.currencyCode) {
          nonSelected.splice(index, 1);
        }
      });
      return nonSelected;
    },
    onClose(index) {
      this.formValues.cashAmounts.splice(index, 1);
    },
    /**
     * The values of `today` are in the range of 0-6, where 0 is Sunday and 6 is Saturday
     *
     * @returns {number}
     */
    getDayOfWeek() {
      return this.$dayjs().format('YYYY-MM-DD');
    },
    handleToggleHasMaxPlayer() {
      this.formValues.hasMaxPlayerCount = !this.formValues.hasMaxPlayerCount;
    },
    handleToggleIsRanked() {
      this.formValues.isRanked = !this.formValues.isRanked;
    },
    handleAddInvitation(profileToInvite) {
      const invitationIndex = this.profilesToInvite.findIndex((profile) => {
        return profile.profileId === profileToInvite.profileId;
      });

      if (invitationIndex !== -1) {
        this.profilesToInvite.splice(invitationIndex, 1);
      } else {
        if (
          !this.formValues.hasMaxPlayerCount ||
          this.profilesToInvite.length < this.formValues.maxPlayerCount
        ) {
          this.profilesToInvite.push(profileToInvite);
        }
      }
    },
    handleRemoveInvitation(profileId) {
      const invitedProfileIndex = this.profilesToInvite.findIndex((profile) => {
        return profile.profileId === profileId;
      });

      if (invitedProfileIndex !== -1) {
        this.profilesToInvite.splice(invitedProfileIndex, 1);
      }
    },
  },
};
