
import { mapActions, mapGetters } from 'vuex';
import UICreateLeagueModal from '@/components/ui/organism/UICreateLeagueModal';

import { startTransactionAsync } from '~/utils/instrumentation/transactions';
import { showErrorToast } from '~/utils/toast-notifications';

export default {
  name: 'CreateLeagueModalWrapper',
  components: { UICreateLeagueModal },
  computed: {
    ...mapGetters('authentication', [
      'isEmailConfirmed',
      'profileId',
      'isUserAdmin',
    ]),
    ...mapGetters('leagues', ['createAdminLeagueRes']),
  },
  methods: {
    ...mapActions('leagues', ['createAdminLeague']),
    ...mapActions('modals', ['toggleCreateLeagueModal']),
    async handleCreate(data) {
      try {
        if (!this.isUserAdmin) {
          return;
        }
        await startTransactionAsync('Create Admin League', async () => {
          // For now only admin creation is on
          await this.createAdminLeague(data);

          if (this.createAdminLeagueRes.data) {
            this.toggleCreateLeagueModal();
          } else throw new Error('Failed to create portfolio');
        });
      } catch (e) {
        console.error('Create league transaction failed', e);

        const errorTranslationKey = 'error_occured_message';

        showErrorToast(this.$t(errorTranslationKey));
      }
    },
    handleClose() {
      this.toggleCreateLeagueModal();
    },
  },
};
