import { gaEventManager } from './ga-event-manager';

// This file is responsible for raising analytics events to analytics providers such as Google Analytics.

// This file should not have any implementation details of the analytics provider, only the event raising logic.

// Different analytics providers may require specific formats of events, so the formatting should be handled in the provider-specific manager objects/modules, rather than here.

/**
 * Guards against exceptions thrown when raising an event.
 * @param func The function to be called
 * @param args The arguments to be passed to the function
 */
function raiseEventGuard(
  func: (...args: unknown[]) => void,
  ...args: unknown[]
) {
  return () => {
    try {
      func(...args);
    } catch (ex: unknown) {
      console.error('Failed to raise event', ex);
    }
  };
}

/**
 * Raises an event to indicate that portfolio creation has started.
 * This should not be confused with opening the portfolio creation modal.
 * This event should be raised when an API call is made to create a portfolio.
 */
function raisePortfolioCreationStartedEvent() {
  gaEventManager.raisePortfolioCreationStartedEvent();
}

/**
 * Raises an event to indicate that a portfolio has been successfully created.
 */
function raisePortfolioCreatedEvent() {
  gaEventManager.raisePortfolioCreatedEvent();
}

/**
 * Raises an event to indicate that portfolio creation has failed.
 * This event should be raised when an API call to create a portfolio fails.
 */
function raisePortfolioCreationFailedEvent() {
  gaEventManager.raisePortfolioCreationFailedEvent();
}

/**
 * The event manager object is responsible for raising events to indicate that specific application behaviors have occurred.
 * This object should be used to raise analytics events when certain application behaviors occur.
 */
export const eventManager = {
  raisePortfolioCreationStartedEvent: raiseEventGuard(
    raisePortfolioCreationStartedEvent
  ),
  raisePortfolioCreatedEvent: raiseEventGuard(raisePortfolioCreatedEvent),
  raisePortfolioCreationFailedEvent: raiseEventGuard(
    raisePortfolioCreationFailedEvent
  ),
};
