
import { isMobile } from '~/utils/screenSizes';
import CloseIcon from '~/components/ui/icons/CloseIcon.vue';
export default {
  name: 'PortfolioSearchResultCard',
  components: { CloseIcon },
  props: {
    portfolio: {
      type: Object,
      default: null,
    },
    avatarUri: {
      type: String,
      default: '',
    },
    ownerDisplayName: {
      type: String,
      default: '',
    },
    portfolioName: {
      type: String,
      default: '',
    },
    overallChangePercent: {
      type: Number,
      default: 0,
    },
    stocksCount: {
      type: Number,
      default: 0,
    },
    lastUpdated: {
      type: String,
      required: true,
    },
    showCloseIcon: {
      type: Boolean,
      default: true,
    },
    isMainPortfolio: {
      type: Boolean,
      default: false,
    },
    showColorIndicator: {
      type: Boolean,
      default: false,
    },
    indicatorColor: {
      type: String,
      default: '',
    },
  },
  computed: {
    isMobileSize() {
      return isMobile();
    },
    getIndicatorColorClass() {
      return 'bg-' + this.indicatorColor;
    },
    fromNow() {
      return this.$dayjs(this.lastUpdated)
        .subtract('1', 's')
        .startOf('s')
        .fromNow();
    },
  },
  methods: {
    handlePortfolioRemove() {
      this.$emit('onPortfolioRemove', this.portfolio);
    },
    handleClick() {
      console.log('click');
    },
  },
};
