import { inAppRedirect } from '~/utils/route-redirects';
import Vue from 'vue';

/**
 * @description
 * Directive used to be able to work with internal links inside of v-html content
 * and still use friendly router navigation rather than browser navigation that reloads the whole page
 *
 * @usage
 *  <div
 *  v-interpolate
 *  v-html="htmlBody"
 *  />
 */
const interpolate = {
  bind(el) {
    const links = Array.from(el.getElementsByTagName('a')).filter((linkEl) => {
      const href = linkEl.getAttribute('href');
      if (!href) {
        return false;
      }

      return isInternalLink(href);
    });

    addListeners(links);
    // cleanup
    el.$componentUpdated = () => {
      removeListeners(links);
      this.$nextTick(() => addListeners(links));
    };
    el.$destroy = () => removeListeners(links);
  },
  unbind: (el) => el.$destroy?.(),
};

function navigate(event) {
  const target = event.target;
  const href = target.getAttribute('href');
  event.preventDefault();
  return inAppRedirect(href);
}

function addListeners(links) {
  links.forEach((link) => {
    link.addEventListener('click', navigate, false);
  });
}

function removeListeners(links) {
  links.forEach((link) => {
    link.removeEventListener('click', navigate, false);
  });
}

function isInternalLink(href) {
  return href?.startsWith('/');
}

Vue.directive('interpolate', interpolate);
