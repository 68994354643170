
import { inAppRedirect, appURLs } from '~/utils/route-redirects';

export default {
  name: 'NotificationLeagueInvite',
  props: {
    notif: {
      required: true,
      type: Object,
    },
  },
  methods: {
    goToTarget() {
      inAppRedirect(appURLs.leagues());
    },
  },
};
