import { eventTypes } from './event-types';

// This file is responsible for raising Google Analytics events.
// It is important to note that this file is not responsible for the implementation of Google Analytics - it's only responsible for raising events under the assumption that GA is configured elsewhere.

declare global {
  interface Window {
    gtag: undefined | null | ((...args: unknown[]) => void);
  }
}

/**
 * This function raises a gtag.js event.
 * @param eventType - the type of the event to raise
 * @param eventParameters - the event parameters
 * @returns void
 * @description The function checks if the gtag function is present on the window object. If it is not, an error is logged.
 */
function raiseGtagEvent(
  eventType: string,
  eventParameters: object | undefined
) {
  // check if gtag.js is present - if not, log an error
  if (!window.gtag) {
    console.error('gtag is not defined');
    return;
  }

  window.gtag('event', eventType, eventParameters);
}

/**
 * This function should be used to raise GA events.
 * @description This function should be used to raise GA events. In case we decide to switch from gtag.js to GTM, make that change here by simply calling a different function (e.g. raiseGTMEvent).
 * @param eventType - the type of the event to raise
 * @param eventParameters - the event parameters
 */
function raiseEvent(eventType: string, eventParameters?: object | undefined) {
  // NOTE: in case we decide to switch to google tag manager (GTM) instead of gtag.js, we have to update nuxt.config.js:71 as well as this line
  raiseGtagEvent(eventType, eventParameters ?? {});
}

/**
 * Raises the portfolio creation started event.
 */
function raisePortfolioCreationStartedEvent() {
  raiseEvent(eventTypes.portfolioCreationStarted);
}

/**
 * Raises the portfolio created event.
 */
function raisePortfolioCreatedEvent() {
  raiseEvent(eventTypes.portfolioCreated);
}

/**
 * Raises the portfolio creation failed event.
 */
function raisePortfolioCreationFailedEvent() {
  raiseEvent(eventTypes.portfolioCreationFailed);
}

/**
 * The event manager object for Google Analytics (GA).
 * @description All events that should be raised towards GA should have a corresponding function implementation here, and be exported towards the main event manager in utils/behavior/applicationEvents/event-manager.ts
 */
export const gaEventManager = {
  raisePortfolioCreationStartedEvent,
  raisePortfolioCreatedEvent,
  raisePortfolioCreationFailedEvent,
};
