import { render, staticRenderFns } from "./UIProfileSearchWithCardsV2.vue?vue&type=template&id=846b96da"
import script from "./UIProfileSearchWithCardsV2.vue?vue&type=script&lang=js"
export * from "./UIProfileSearchWithCardsV2.vue?vue&type=script&lang=js"
import style0 from "./UIProfileSearchWithCardsV2.vue?vue&type=style&index=0&id=846b96da&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProfileImg: require('/skade-pwa/components/ui/atom/ProfileImg.vue').default})
