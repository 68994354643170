
import UISimpleProfileCard from '@/components/ui/organism/UISimpleProfileCard';
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'UIProfileSearchWithCardsV2',
  components: { UISimpleProfileCard },
  props: {
    profilesToInvite: {
      type: Array,
      default() {
        return [];
      },
    },
    titleText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      query: '',
      pickerVisible: false,
      currentIndex: 0,
    };
  },
  computed: {
    ...mapGetters('search', ['searchRes']),
  },
  watch: {
    'searchRes.data.mentions'() {
      this.filterAlreadyAddedUsersFromSearch();
    },
  },
  methods: {
    ...mapActions('search', ['search']),
    handleSearchDebounce: debounce(function (query) {
      if (query) {
        const query = this.query;
        this.search({ type: '@', query, limit: 5 });
      }
    }, 300),
    handleSearch() {
      this.handleSearchDebounce(this.query);
    },
    handleClickOutside() {
      this.pickerVisible = false;
    },
    filterAlreadyAddedUsersFromSearch() {
      if (this.searchRes.mentions?.length === 0) {
        return this.searchRes.mentions;
      }

      const filteredSearchRes = this.searchRes.data.mentions;

      // Filtering out users that are already in the list
      this.profilesToInvite.forEach((profile) => {
        const profileIndex = filteredSearchRes.findIndex(
          (existingProfile) => existingProfile.profileId === profile.profileId
        );

        if (profileIndex !== -1) {
          filteredSearchRes.splice(profileIndex, 1);
        }
      });
      return filteredSearchRes;
    },
    getCardBackgroundColor(profileId) {
      const isProfilePicked = this.profilesToInvite.some((invitedProfile) => {
        return invitedProfile.profileId === profileId;
      });
      return isProfilePicked ? 'bg-primary-1' : '';
    },
  },
};
