/**
 * Redirects user to a portfolio
 * @param portfolioId which portfolio we want to redirect user to
 */
export function redirectToPortfolio(portfolioId: string): void {
  window.$nuxt.$router.push(`/portfolios/${portfolioId}`);
}

/**
 * Redirects user to a profile
 * @param portfolioId which profile we want to redirect user to
 */
export function redirectToProfile(profileId: string): void {
  window.$nuxt.$router.push(`/profile/${profileId}`);
}

/**
 * Redirects user to a post
 * @param postId which post we want to redirect user to
 */
export function redirectToPost(postId: string): void {
  inAppRedirect(`/${appURLs.post()}/${postId}`);
}

/**
 * Redirects the user to the specified route, while preserving their current locale.
 * Use this function only on the Nuxt client side and only for in-app navigation, as this will not handle out-of-app URLs properly
 * @param route The route to redirect to.
 * @returns {void}
 */
export function inAppRedirect(route: string | object): void {
  window.$nuxt.$router.push(window.$nuxt.localePath(route));
}

// NOTE: keep this in sync with utils/behavior/scrollBehavior.js:17
/**
 * Provides an application-level routing scheme.
 */
export const appURLs = {
  index: () => 'index',
  // TODO replace all /feed instances with this
  // TODO replace all localePath('feed') instances with this
  // TODO you can use something like the following in case localePath is used directly in the component template
  // feedUrl() {
  //   return this.localePath(appURLs.feed());
  // },
  feed: () => 'index',
  onboarding: () => 'onboarding',
  loading: () => 'loading',
  portfolio: () => 'portfolio',
  portfolios: () => 'portfolios',
  profile: () => 'profile',
  profileById: (id: string) => ({
    name: 'profile-profileId',
    params: { profileId: id },
  }),
  messages: () => 'messages',
  market: () => 'market',
  stocks: () => 'stocks',
  crypto: () => 'crypto',
  saved: () => 'saved',
  settings: () => 'settings',
  post: () => 'post',
  postById: (id: string) => ({
    name: 'post-postId',
    params: {
      postId: id,
    },
  }),
  notification_center: () => 'notifications-center',
  leagues: () => 'leagues',
  leaguesById: (leagueId: string) => ({
    name: 'leagues',
    query: {
      leagueId: leagueId,
    },
  }),
  allLeagues: () => 'leagues/all',
  searchResults: () => 'searchResult',
  searchResultsByQuery: (query: string) => ({
    name: 'searchResult',
    params: {
      index: query,
    },
  }),
  recommendedProfiles: () => 'profile-recommended',
  portfoliosSettings: () => 'portfolios-settings',
  portfoliosPortfolioSettings: (portfolioId: string) => ({
    name: 'portfolios-settings',
    query: {
      portfolio: portfolioId,
    },
  }),
};
