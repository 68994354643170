import { render, staticRenderFns } from "./UICreateLeagueModal.vue?vue&type=template&id=68408660&scoped=true"
import script from "./UICreateLeagueModal.vue?vue&type=script&lang=js"
export * from "./UICreateLeagueModal.vue?vue&type=script&lang=js"
import style0 from "./UICreateLeagueModal.vue?vue&type=style&index=0&id=68408660&prod&lang=scss"
import style1 from "./UICreateLeagueModal.vue?vue&type=style&index=1&id=68408660&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68408660",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/skade-pwa/components/ui/atom/buttons/Button.vue').default,PremiumOnlyTag: require('/skade-pwa/components/ui/atom/PremiumOnlyTag.vue').default,Modal: require('/skade-pwa/components/ui/atom/Modal.vue').default})
